<!--
 * @Author: wangwenqiu@suanier.com wangwenqiu@suanier.com
 * @Date: 2023-05-09 14:29:55
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-19 15:52:03
 * @FilePath: \vr-exp-h5-frontend\src\pages\save-qr\index.vue
 * @Description: 
-->
<template>
    <div :class="['save-page',$i18n.locale]">
        <div class="back-button">
            <span>
                <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click.prevent="goBack()" />
            </span>

        </div>
        <div class="title">
            <img src="@/assets/images/sidebar/title_image@2x.png" alt class="menu-icon" />
        </div>
        <div :class="['middle-content',$i18n.locale]">
            <span class="p1"> {{ $t('saveQr.title') }} </span>
            <div id="canvas" @touchstart="onTouchstart()" @touchmove="ontouchmove()" @touchend="onTouchend()"></div>
            <span class="p2"> {{ $t('saveQr.btnMsg') }} </span>
            <span class="p3"> {{ $t('saveQr.tips') }} </span>
        </div>
    </div>
</template>
<script>
import QRCodeStyling from 'qr-code-styling'
export default {
    data() {
        return {
            url: '',
            qrCode: {},
            timeOutEvent: 0,
        }
    },

    mounted() {
        const locationUrl = document.URL || document.location
        const path = this.$route.path
        this.url = locationUrl.split(path)[0] || locationUrl
        this.onUpdateQrcode()
    },
    methods: {
        onUpdateQrcode() {
            if (this.qrCode.data === null || this.qrCode.data === undefined) {
                this.qrCode = new QRCodeStyling({
                    width: 220,
                    height: 220,
                    type: "svg",
                    data: this.url,
                    dotsOptions: {
                        color: "#000",
                        type: "rounded"
                    },
                    backgroundOptions: {
                        color: "#fff",
                    },
                    imageOptions: {
                        crossOrigin: "anonymous",
                        margin: 20
                    }
                });
            }
            this.qrCode.append(document.getElementById("canvas"));
        },
        goBack() {
            this.$router.go(-1)
        },
        longPress() {
            this.qrCode.download({ name: "qr", extension: "png" })
        },
        onTouchstart() {
            var self = this
            this.timeOutEvent = setTimeout(function () {
                self.longPress()
            }, 500)
            return false
        },
        ontouchmove() {
            clearTimeout(this.timeOutEvent)
            this.timeOutEvent = 0
        },
        onTouchend() {
            clearTimeout(this.timeOutEvent)
            return false
        },
    },
}
</script>
<style lang="less" scoped>
.save-page {
    width: 100%;
    height: 100%;
    background: url('../../assets/images/sidebar/save_qr_bg@2x.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;

    .back-button {
        position: absolute;
        top: 21px;
        left: 24px;

        .menu-info {
            width: 24px;
            height: 24px;
        }
    }

    .title {
        margin-top: 46px;
        margin-bottom: 122px;
        width: 100%;
        align-items: center;

        .menu-icon {
            width: 210px;
            height: 27px;
        }
    }

    .middle-content {
        display: flex;
        align-items: center;
        height: auto;
        flex-direction: column;
        width: 100%;

        .p1 {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #141E3D;
            line-height: 20px;
            margin-bottom: 24px;
        }

        .p2 {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
            margin: 27px 24px 0px 24px;
        }

        .p3 {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #141E3D;
            line-height: 18px;
            text-shadow: 0px 1px 16px #FFFFFF;
            margin: 17px 24px 0px 24px;
        }
    }
    .ar-AR{
        .p1,.p2,.p3{
            text-align: right;
        }
        direction: rtl;
        .p3{
            padding-right: 20px;
        }
    }
}
</style>